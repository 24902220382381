import React, { useContext, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroup,
  Container,
  Col
} from "reactstrap";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { postServer } from "../services/server";
import { CompanyContext } from "../contexts/CompanyContaxt";
import { Authenticated, User } from "../contexts/SessionContext";

export default function Login() {
  const company = useContext(CompanyContext);
  const {user, setUser} = useContext(User);
  const {authenticated, setAuthenticated} = useContext(Authenticated);
  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const inputs = [{
    id: 1,
    name: 'email',
    type: 'email',
    placeholder: 'Email',
    size: '12',
    errorMessage: 'Please enter Email',
    required: true,
  },
  {
    id: 2,
    name: 'password',
    type: 'password',
    placeholder: 'Password',
    size: '12',
    errorMessage: 'Need your password',
    required: true
  }];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  const handleSubmit = () => {
    let data = values;
    let conpanyData = company;
    postServer(`/adminlogins`, {
      company: conpanyData._id,
      email : data.email,
      password : data.password
    }).then(res => {
      console.log(res)
      setUser(res.admin)
      setAuthenticated(res.authenticated)
    }).catch(err => {
      console.log('Something went wrong')
      console.log(err)
      
    });
  };



  return (
    <>
      <NavBar />
      <div className="page-header">
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="page-header-image" />
        <Container>
          <Col className="mx-auto" lg="5" md="8">
            <Card className="card-login">
              <Form action="" className="form" method="">
                <CardHeader>
                  <CardImg
                    alt="..."
                    src={require("../assets/img/square-purple-1.png")}
                  />
                  <CardTitle tag="h4">Login</CardTitle>
                </CardHeader>
                <CardBody>
                  {inputs.map(data => (
                    <InputGroup>
                      <Input key={data.id}  {...data} value={values[data.name]} onChange={onChange} />
                    </InputGroup>
                  ))}
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    block
                    className="btn-round"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Get Started
                  </Button>
                </CardFooter>
                <div className="pull-left ml-3 mb-3">
                  <h6>
                    <a
                      className="link footer-link"
                      href="#pablo"
                      onClick={handleSubmit}
                    >
                      Create Account
                    </a>
                  </h6>
                </div>
                <div className="pull-right mr-3 mb-3">
                  <h6>
                    <a
                      className="link footer-link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Need Help?
                    </a>
                  </h6>
                </div>
              </Form>
            </Card>
          </Col>
        </Container>
      </div>
      <Footer />
    </>
  );
}
