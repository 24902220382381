import { createContext } from "react";

// const Session = createContext({
//   admin: {},
//   setAdmin: () => { },
//   user: {},
//   setUser: () => { },
//   authenticated: false,
//   setAuthenticated: () => Boolean
// });

const User = createContext({
  user : {},
  setUser : () => {}
});

const Authenticated = createContext({
  authenticated : false,
  setAuthenticated : () => Boolean
});

export { User, Authenticated };
