import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import { Form, Input } from 'reactstrap';
import { CompanyContext } from "../contexts/CompanyContaxt";
import { postServer } from '../services/server';

export default function Subscribe() {
  const company = useContext(CompanyContext);
  const [focused, setFocused] = useState(false);


  const [values, setValues] = useState({
    email: '',
  });

  const resetForm = () => {
    setValues({
      email: '',
    });
  }

  const handleFocused = (e) => {
    setFocused(true)
  }

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    postServer('/users', {
      email: values.email,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Thank you for subscribing!', 'success');
    }).fail(err => {
      resetForm();
      notify(err.responseJSON, 'error');
    });
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      <section>
        <div className="space-110"></div>
        <div className="subscribe-line subscribe-line-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="title text-white">Want to be the first?</h4>
                <p className="description">
                  Subsribe now and we'll send you exclusive deals and specials right in the palm of your hand.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="card card-plain card-form-horizontal">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="tim-icons icon-mobile"></i></span>
                            </div>
                            <Input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Your Email..."
                              onBlur={handleFocused}
                              focused={focused.toString()}
                              onChange={onChange}
                              required
                              value={values['email']}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <button type="submit" className="btn btn-primary btn-round btn-block">Subscribe</button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="space-110"></div>
    </>
  )
}
