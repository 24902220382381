import React, { useContext, useState, useEffect } from 'react'
import NavBar from './components/NavBar'

import Footer from './components/Footer';
import { CompanyContext } from "./contexts/CompanyContaxt";
import { toast } from 'react-toastify';
import { postServer } from './services/server';
import { Button, Col, Form, Row } from 'reactstrap';
import FormInput from './components/FormInput'

export default function Partnership() {
  const company = useContext(CompanyContext);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const resetForm = () => {
    setValues({
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  const inputs = [{
    id: 1,
    name: 'name',
    type: 'text',
    label: 'Your Name',
    placeholder: 'Your Name',
    size: '6',
    errorMessage: 'Please input your name',
    required: true
  },
  {
    id: 3,
    name: 'phone',
    type: 'phone',
    label: 'Phone',
    placeholder: 'Phone',
    size: '6',
    errorMessage: 'Please input your phone number so we can get incontact with you.',
    required: true
  },
  {
    id: 4,
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Email',
    size: '12',
    errorMessage: 'Please input your email',
    required: true

  },
  {
    id: 6,
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Tell us about your Brand',
    size: '12',
    errorMessage: 'Tell us about your Brand ',
    required: true
  }];

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    postServer('/contacts', {
      first_name: values.name,
      phone: values.phone,
      email: values.email,
      subject: 'Partnerships',
      message: values.message,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Your message has been sent!', 'success');
    })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      <NavBar />
      <div className="contactus-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 style={{marginTop: 50}} className="title text-white">Partnerships</h1>
              <h4 className="description text-white">Welcome to our Partnerships! We proudly collaborate with a diverse range of talented brands, creating synergies for unforgettable events. Dive in to learn more about our partners and the magic we craft together for the ultimate nightlife experience.</h4>
            </div>
            <div className="col-md-12 m-auto">
              <div className="card card-contact card-raised">
                <div className="row">
                  <div style={{ padding: 50 }} className="col-md-12">
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        {inputs.map((input) => (
                          <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
                        ))}
                      </Row>
                      <Row className="justify-content-end">
                        <Col className="text-right" md="6">
                          <Button color="primary" type="submit">
                            Submit Inquiry
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
