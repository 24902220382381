import ReactGA from 'react-ga';

export default function Analytics() {
    ReactGA.initialize('UA-243653213-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return;
}

export const EventTracker = ({category, action, label}) => {
    ReactGA.initialize('UA-243653213-1');
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
}
