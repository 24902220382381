import React from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col
} from "reactstrap";
import { EventTracker } from '../services/Analytics';

export default function Faq() {
  const [collapse, setCollapse] = React.useState(1);
  const openCollapse = ({ id, faq }) => {
    setCollapse(id);
    EventTracker({
      category: 'FAQ',
      action: faq.q,
      label: faq.a
    })
  };

  const questions = [{
    id: '1',
    q: 'What are the hours of operation?',
    a: 'We\'re open every Saturday. Hours of Operation are from 9:30pm to 2:00am.'
  },
  {
    id: '2',
    q: 'What is the age requirement for entry?',
    a: 'Club X is a 18+ event.'
  },
  {
    id: '3',
    q: 'What is Club X Dress Code?',
    a: 'Dress to impress. No hoodies, sports attire, hats or inappropriate/ offensive wording on clothing.'
  },
  {
    id: '4',
    q: 'Where do I park?',
    a: 'There is free parking all along Harrison Street. Additionally, there is a paid parking lot on the East side of our building. Please refrain from keeping any valuables inside your vehicle.'
  },
  {
    id: '5',
    q: 'What is the cover charge?',
    a: 'Admission at the door is $20 unless stated on the event flyer.'
  },
  {
    id: '6',
    q: 'Do you offer Guest List?',
    a: 'Yes, We have promoters all over the Bay Area. Reach out to them to be added on to a guest list.'
  },
  {
    id: '7',
    q: 'Do you guys have a coat-check?',
    a: 'Yes. We have a coat check located past our front door to the left.'
  },
  {
    id: '8',
    q: 'Refund Policy',
    a: 'Our tickets are a final sale. No refunds, exchanges, or cancellations.'
  },
  {
    id: '9',
    q: 'How do I book a Private Event',
    a: 'Click this link for more information: ',
    linkName: '715harrison.com',
    link: 'https://715harrisoneventspace.com'
  }];

  return (
    <>
      <div className="cd-section" id="accordion">
        {/* ********* Accordion 1 ********* */}
        <div className="accordion-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title mb-4 mt-5 text-white">Frequently Asked Question</h2>
                <div className="section-space" />
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="12">
                <div className="accordion">
                  {
                    questions.map(data => (
                      <Card key={data.id}>
                        <CardHeader>
                          <h5 className="mb-0">
                            <Button
                              className="w-100 text-left"
                              color="link"
                              aria-expanded={collapse === data.id}
                              onClick={() => openCollapse({
                                id: data.id,
                                faq: data
                              }
                              )}
                            >
                              {data.q}{" "}
                              <i className="tim-icons icon-minimal-down float-right" />
                            </Button>
                          </h5>
                        </CardHeader>
                        <Collapse isOpen={collapse === data.id}>
                          <CardBody>
                            {data.a} {data?.link ? <a target="_BLANK" rel="noreferrer" href={data.link}>{data?.linkName}</a> : ''}
                          </CardBody>
                        </Collapse>
                      </Card>
                    ))
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}
