import React from 'react'
import { Col, Row } from 'reactstrap';
import GradientFull from '../GradientFull';

function BasicHeader({title, logo, byline, headerimage, size }) {


  return (
    <div className="page-header header-filter">
      <GradientFull />
      <div
        className="page-header-image"
        style={{
          backgroundImage: `url(${headerimage})`
        }}
      />
      <div className="content-center">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <div className="text-center">
              {logo ? (
                <img
                  style={{ maxHeight: 300 }}
                  className='basic-header-logo'
                  src={logo}
                  alt='logo' />

              ) : (
                <h1 style={{ fontFamily: 'mainFont', fontSize: 55, marginBottom: 5, marginTop: 10 }} className="title text-white">
                  {title}
                </h1>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <h5 style={{ fontFamily: 'mainFont', fontSize: size ? size : 24 }} className='text-center basic-header-text text-white'>{byline}</h5>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BasicHeader