import React from 'react'

function IconStickers({name, image}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 1rem',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#fff'
      }}
    >
      <img src={image} alt="IconStickers"  />
      <p>{name}</p>
    </div>
  )
}

export default IconStickers