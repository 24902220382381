import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';

export default function ContactMap({zoom}) {


    const iconPerson = new L.Icon({
        iconUrl: require('../assets/img/clubxMarker.png'),
        shadowUrl: require('../assets/img/shadow.png'),
        iconSize:     [40, 97], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [10, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup shouåld open relative to the iconAnchor
    });

    return (
        <div className='map'>
            <MapContainer id='mapid' center={[37.782147, -122.398917]} zoom={zoom || 17} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://Liveonbeat.com">Live On Beat</a>'
                    url="https://api.mapbox.com/styles/v1/djstewie/ckkpf1ldb15gq17qk2lmqmvhc/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGpzdGV3aWUiLCJhIjoiY2trcGQ2MW84MDZhNjJ5bzVrbm8xemhycSJ9.y592_-MfDaduUdSMbT66iQ"
                />
                <Marker position={[37.782047, -122.397600]} icon={iconPerson}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}
