import { CompanyContext } from '../contexts/CompanyContaxt'
import React, { useContext } from 'react'
import { Image } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export default function Logo(props) {
  const company = useContext(CompanyContext);
  const [imageW, setImageW] = React.useState(null);
  const [imageH, setImageH] = React.useState(null);
  const logo = company.logoLight;

  React.useEffect(() => {
    if (props.size) {
      setImageW(props.size)
      setImageH('auto')
    } else {
      let img = document.createElement('img');
      img.id = 'imgId';
      img.src = logo;

      let realWidth = img.naturalWidth;
      let realHeight = img.naturalHeight;
      setImageW(realWidth)
      setImageH(realHeight)
    }
    return
  }, [props.size, logo])

  return (
    <div className='crop'>
      {logo ? (
        <Image src={logo} fluid={true} alt="Company Logo" className='main-logo' style={{
          height: imageH,
          width: imageW
        }} />
      ) : <Skeleton height={props.size} width={props.size} circle={true} />}
      
    </div>

  )
}
