import jQuery from 'jquery';
let route = '/api';
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     route = 'http://localhost:4242';
// } else {
//     route = '/api';
// }

function getServer (url) {
    return jQuery.getJSON(`${route}${url}`);
}

function postServer (url, data) {
    if (typeof data === typeof {}) {
      data = JSON.stringify(data);
    }
    return jQuery.ajax({
      url : `${route}${url}`,
      type: 'POST',
      contentType: "application/json; charset=utf-8",
      Origin: "*",
      dataType: "json",
      data : data,
    });
}

function putServer (url, data) {
    if (typeof data === typeof {}) {
      data = JSON.stringify(data);
    }
    return jQuery.ajax({
      url : `${route}${url}`,
      type: 'PUT',
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data : data
    });
  }

function deleteServer (url) {
    return jQuery.ajax({
      url : `${route}${url}`,
      type: 'DELETE'
    });
}

export {getServer, postServer, putServer, deleteServer};
