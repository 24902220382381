import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Input } from 'reactstrap'
import { getServer, postServer } from '../../services/server'
import { toast } from 'react-toastify';
import { Redirect, useParams } from 'react-router-dom'

const Imputs = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, type, options, size, ...inputProps } = props;

  const handleFocused = (e) => {
    setFocused(true)
  }

  return (
    <Col md={size}>
      <Form.Group className="mb-3">
        <Form.Label style={{ marginLeft: 10 }}>{label}</Form.Label>
        <Form.Control
          {...inputProps}
          onChange={onChange}
          type={type}
          onBlur={handleFocused}
          focused={focused.toString()}
          className="form-control"
          style={{ height: 50, borderColor: 'gray', borderWidth: 1, backgroundColor: 'white', margin: 8, borderRadius: 8, paddingHorizontal: 15, color: 'white', ':placeholder': { color: 'black !important' } }}
        />
      </Form.Group>
    </Col>
  )
}

function QrLink() {
  const { id } = useParams();
  const [pageData, setPageData] = useState({});
  const [nothingToSee, setNothingToSee] = useState(false);
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    dob: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    async function getData() {
      try {
        const response = await getServer(`/submissions/qrcode/linkcheck/${id}`);
        if (response?.active) {
          setPageData(response.submission);
        } else {
          toast('This link has expired.', {
            type: 'warning' || 'default',
            theme: 'dark',
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setNothingToSee(true);
        }
      } catch (err) {
        console.log('page error', err);
        setNothingToSee(true);
      }
    }
    getData();
  }, [id]);

  if (nothingToSee) {
    return <Redirect to="/" />;
  }

  const resetForm = () => {
    setValues({
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      dob: ''
    });
  }

  const inputs = [{
    id: 1,
    name: 'first_name',
    type: 'text',
    label: 'First Name',
    placeholder: 'First Name',
    size: '4',
    errorMessage: 'Please input your first_name',
    required: true
  },
  {
    id: 3,
    name: 'last_name',
    type: 'text',
    label: 'Last Name',
    placeholder: 'Last Name',
    size: '4',
    errorMessage: 'Please enter your last name.',
    required: true
  },
  {
    name: 'city',
    type: 'text',
    label: 'City',
    placeholder: 'Your City',
    size: '4',
    errorMessage: 'Please enter your phone number',
    required: true
  },
  {
    id: 4,
    name: 'phone',
    type: 'phone',
    label: 'Phone',
    placeholder: 'Phone Number',
    size: '6',
    errorMessage: 'Please enter your phone number',
    required: true

  },
  {
    id: 4,
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Email',
    size: '6',
    errorMessage: 'Please enter your email',
    required: true
  },
  {
    id: 4,
    name: 'dob',
    type: 'date',
    label: 'Date of Birth',
    placeholder: 'Date of Birth',
    size: '3',
    errorMessage: 'Please enter your date of birth',
    required: true
  }];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const submit = (e) => {
    e.preventDefault();

    postServer('/users/qr/create', {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      email: values.email,
      dob: values.dob,
      city: values.city,
      user_type: pageData?.usertype?._id,
      company: pageData?.company,
      submission: pageData?._id
    }).then(res => {
      console.log(res)
      toast('Thank you for the information. Be on the lookout for an email.', {
        type: 'default',
        theme: 'dark',
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setNothingToSee(true);
      resetForm()
    }).fail(err => {
      console.log(err)
    })

  }

  return (
    <>
      <NavBar />
      <div className="contactus-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 style={{ fontWeight: 'bold' }} className="text-white margin-top-40">
                <span style={{ textTransform: 'uppercase'}}>{pageData?.usertype?.name}</span> Memebers
               
              </h1>
              <h4 style={{ fontWeight: 300 }} className="text-white">
                Receive updates and discounts on <b>Tickets</b> to our events. pleaase note; We do <b>not</b> sale information.
              </h4>
            </div>
            <div className="col-md-12 m-auto">
              <div className="card card-contact card-raised">
                <div style={{ padding: 50 }}>
                  <Row className='margin-bottom-30'>
                    <Col>
                      <span style={{ fontWeight: 'bold', fontSize: 15, color: '#ccc' }} className="text-white">
                        <span style={{ fontSize: 24, fontWeight: 'bold' }}>C</span>omplate this form to become a <span style={{ fontSize: 24, fontWeight: 'bold', color: 'gold', textTransform: 'uppercase' }}>{pageData?.usertype?.name}</span> Memeber. You will receive updates and free Tickets to our events. pleaase note; We do <b>not</b> sale information.
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    {inputs.map((input, key) => <Imputs key={key} {...input} value={values[input.name]} onChange={onChange} />)}
                    <Col md={12}>
                      <Button onClick={submit} style={{ width: 200 }} className='pull-right margin-top-20' color="primary">Join</Button>
                    </Col>

                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default QrLink