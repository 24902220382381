import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Col, Container, Form, Row } from 'reactstrap'
import Footer from '../../components/Footer'
import FormInput from '../../components/FormInput';
import NavBar from '../../components/NavBar'
import { CompanyContext } from '../../contexts/CompanyContaxt';
import Analytics, { EventTracker } from '../../services/Analytics';
import { postServer } from '../../services/server';

function Book() {
  const company = useContext(CompanyContext);
  const [values, setValues] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    message: '',
    business: '',
    date: '',
    attendees: '',
    bar: '',
    catering: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Analytics();
  }, []);

  const tracker = () => {
    EventTracker({
      category: 'Events',
      action: `Submitted rental form`,
      label: moment().format('lll')
    })
  }

  const resetForm = () => {
    setValues({
      fname: '',
      lname: '',
      email: '',
      phone: '',
      message: '',
      business: '',
      date: '',
      attendees: '',
      bar: '',
      catering: '',
    });
  }

  const barOptions = [
    { value: 'Cash Bar', label: 'Cash Bar' },
    { value: 'Open Bar', label: 'Open Bar' },
    { value: 'No Bar', label: 'No Bar' },
    { value: 'Other', label: 'Other' },
  ];

  const cateringOptions = [
    { value: 'No to Catering', label: 'No Catering' },
    { value: 'Yes to Catering', label: 'Yes Catering' },
    { value: 'Not sure for Catering', label: 'Not Sure' },
  ]

  const inputs = [{
    name: 'fname',
    type: 'text',
    label: 'First Name',
    placeholder: 'First Name',
    size: '4',
    errorMessage: 'Please input your first name',
    required: true
  },
  {
    name: 'lname',
    type: 'text',
    label: 'Last Name',
    placeholder: 'Last Name',
    size: '4',
    errorMessage: 'Please input your last name',
    required: true
  },
  {
    name: 'phone',
    type: 'phone',
    label: 'Phone',
    placeholder: 'Phone',
    size: '4',
    errorMessage: 'Please input your phone number so we can get incontact with you.',
    required: true
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Email',
    size: '12',
    errorMessage: 'Please input your email',
    required: true

  },
  {
    name: 'business',
    type: 'text',
    label: 'Name of your business / organization? (if any)',
    placeholder: 'EG: Mirror Corp Events, www.SFClubs.com, etc.',
    size: '12'
  },
  {
    name: 'date',
    type: 'datetime-local',
    label: 'Your event date & start time',
    placeholder: 'EG: Mirror Corp Events, www.SFClubs.com, etc.',
    size: '3',
    errorMessage: 'Please input a date and time',
    required: true
  },
  {
    name: 'attendees',
    type: 'number',
    label: 'Number of Attendees',
    placeholder: 'How many people will attend your event?',
    size: '3'
  },

  {
    name: 'bar',
    type: 'select',
    label: 'Type of bar?',
    size: '3',
    options: barOptions,
  },

  {
    name: 'catering',
    type: 'select',
    label: 'Catering?',
    size: '3',
    options: cateringOptions,
  },

  {
    name: 'message',
    type: 'textarea',
    label: 'Additional questions or comments:',
    placeholder: 'Tell us about your event',
    size: '12',
    errorMessage: 'Please tell us about your project so we can better understand',
  }];

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    postServer('/bookings/sfclubs/booking', {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      phone: values.phone,
      message: values.message,
      business_name: values.business,
      start_date: values.date,
      attendees: values.attendees,
      bar: values.bar,
      catering: values.catering,
      company : company._id
    }).then(() => {
      tracker();
      resetForm();
      notify('Booking Request Sent!', 'success');
    }).fail(err => {
      notify(err, 'warning');
    });
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      <NavBar />
      <div className="section about-description">
        <Container>
          <Row className="mb-5">
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className='title'>
                715 Harrison St | Inquiry Form
              </h2>
              <h4 className="description">
                Complete the form below then click submit.  Our event coordinator will contact you shortly. <br />
                We look forward to being a part of building your event!
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {inputs.map((input, key) => (
                    <FormInput key={key} {...input} value={values[input.name]} onChange={onChange} />
                  ))}
                </Row>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" type="submit">
                      Send Request
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default Book