import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup, Input, Label } from 'reactstrap';


export default function FormInput(props) {
    const [focused, setFocused] = useState(false);
    const { label, errorMessage, onChange, id, type, options, size, ...inputProps } = props;

    const handleFocused = (e) => {
        setFocused(true)
    }

    const SelectInput = (props) => {
        const { options, onChange, ...selectProps } = props;
        return (
            <FormGroup style={{ width: '100%' }}>
                <Input type='select' bsSize="lg" className="form-control" style={{ color: "#6c757c", width: '100%' }} onChange={onchange}>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        )
    }

    return (
        <>
            <Col md={size}>
                <div className="form-group margin-top-30">
                    <Form.Label>{label}</Form.Label>
                    <div className="input-group">
                        {/* <div className="input-group-prepend">
                            <span className="input-group-text"><i className="tim-icons icon-mobile"></i></span>
                        </div> */}
                        {type === 'select' ? (
                            // <SelectInput
                            //     {...inputProps}
                            //     options={props.options}
                            //     onChange={onChange}
                            //     onBlur={handleFocused}
                            //     focused={focused.toString()}
                            //     className="form-control"
                            // />


                            <FormGroup style={{ width: '100%' }}>
                                <Input
                                type='select'
                                bsSize="lg"
                                className="form-control"
                                style={{ color: "#6c757c", width: '100%' }}
                                onChange={onChange}
                                focused={focused.toString()}
                                {...inputProps}
                                >
                                    {options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        ) : (
                            <Input
                                {...inputProps}
                                onChange={onChange}
                                type={type}
                                onBlur={handleFocused}
                                focused={focused.toString()}
                                className="form-control"
                                rows={20}
                            />
                        )}

                    </div>
                    <div className='errorMessage'>{errorMessage}</div>
                </div>
            </Col>
        </>
    )
}