import React, { useContext, useState, useEffect } from 'react'
import NavBar from '../components/NavBar'

import ContactMap from '../components/ContactMap';
import Footer from '../components/Footer';
import { CompanyContext } from "../contexts/CompanyContaxt";
import { toast } from 'react-toastify';
import { postServer } from '../services/server';
import { Button, Col, Form, Row } from 'reactstrap';
import FormInput from '../components/FormInput'
import Subscribe from '../components/Subscribe';
import Analytics from '../services/Analytics';

export default function Contact() {
  const company = useContext(CompanyContext);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Analytics();
  }, []);

  const resetForm = () => {
    setValues({
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  const inputs = [{
    id: 1,
    name: 'name',
    type: 'text',
    label: 'Your Name',
    placeholder: 'Your Name',
    size: '6',
    errorMessage: 'Please input your name',
    required: true
  },
  {
    id: 3,
    name: 'phone',
    type: 'phone',
    label: 'Phone',
    placeholder: 'Phone',
    size: '6',
    errorMessage: 'Please input your phone number so we can get incontact with you.',
    required: true
  },
  {
    id: 4,
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Email',
    size: '6',
    errorMessage: 'Please input your email',
    required: true

  },
  {
    id: 5,
    name: 'subject',
    type: 'text',
    label: 'Subject',
    placeholder: 'Subject',
    size: '6'
  },
  {
    id: 6,
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Tell us about your project',
    size: '12',
    errorMessage: 'Please tell us about your project so we can better understand',
    required: true
  }];

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    postServer('/contacts', {
      first_name: values.name,
      phone: values.phone,
      email: values.email,
      subject: values.subject,
      message: values.message,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Your message has been sent!', 'success');
    })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      <NavBar />
      <div className="contactus-4">
        <ContactMap />
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h1 className="title text-white">Get in Touch</h1>
              <h4 className="description text-white">Do you need more information? Please contact us to find more about our events and services.</h4>
            </div>
            <div className="col-md-12 m-auto">
              <div className="card card-contact card-raised">
                <div className="row">
                  <div style={{ padding: 50 }} className="col-md-8">
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        {inputs.map((input) => (
                          <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
                        ))}
                      </Row>
                      <Row className="justify-content-end">
                        <Col className="text-right" md="6">
                          <Button color="primary" type="submit">
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="col-md-4">
                    <div className="info text-left bg-info">
                      <h4 className="card-title text-white">Contact information</h4>
                      <div className="info info-horizontal mt-5">
                        <ul className="contact-info text-white">
                          <li className="">
                            <span className="my-icon pull-left">
                              <i className="tim-icons icon-square-pin text-white"></i>
                            </span>
                            <span className="entry pull-right text-white">
                              {company.address}<br />
                              {company.city} {company.state} {company.zip}
                            </span>
                          </li>

                          <li className="">
                            <span className="my-icon pull-left">
                              <i className="tim-icons icon-mobile text-white"></i>
                            </span>
                            <span className="entry pull-right text-white">
                              {company.phone}
                            </span>
                          </li>

                          <li className="">
                            <span className="my-icon pull-left">
                              <i className="tim-icons icon-email-85 text-white"></i>
                            </span>
                            <span className="entry pull-right text-white">
                              {company.email}
                            </span>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Faq /> */}
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  )
}
